const rules = {
    User: {
        static: ["user:read", "user-activity:visit"]
    },
    Manager: {
        static: [
            "goal:visit",
            "user:read",
            "user:create",
            "user:edit",
            "user:save",
            "user:delete",
            "team-page:visit",
            "team-activity:visit"
        ],
    },
    Owner: {
        static: [
            "award:visit",
            "goal:visit",
            "user:read",
            "user:create",
            "user:edit",
            "user:save",
            "user:delete",
            "team-page:visit",
            "setting-page:visit",
            "invite-user:visit",
            "owner-activity:visit",
            "productStatus:action"
        ]
    }
};

export default rules;